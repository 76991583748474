<template>
  <div class="inicio">

    

    
    <div class="mainInner"  v-on:scroll="handleScroll" >

      <div class="row" id="home">
        <div class="articulo">
          <a href="/detalle">
            <img src="../assets/girl.jpg" alt="">
          </a>

          <a href="/detalle">
            <p>Articulo 1</p>
          </a>
        </div>

        <div class="articulo">
          <a href="">
            <img src="../assets/girl2.jpg" alt="">
          </a>

          <a href="">
            <p>Articulo 2</p>
          </a>
        </div>

        <div class="articulo">
          <a href="">
            <img src="../assets/girl3.jpg" alt="">
          </a>

          <a href="">
            <p>Articulo 3</p>
          </a>
        </div>
      </div>
      
      <div class="row">
        <div class="articulo">
          <a href="">
            <img src="../assets/girl4.jpg" alt="">
          </a>

          <a href="">
            <p>Articulo 4</p>
          </a>
                    
        </div>

        <div class="articulo">
          <a href="">
            <img src="../assets/girl5.jpg" alt="">
          </a>

          <a href="">
            <p>Articulo 5</p>
          </a>
        </div>

        <div class="articulo">
          <a href="">
            <img src="../assets/girl6.jpg" alt="">
          </a>

          <a href="">
            <p>Articulo 6</p>
          </a>
        </div>
      </div>
     
      
    </div>

    
  </div>
</template>

<script>
  import {RotateSquare2} from 'vue-loading-spinner'
  export default {
    components: 
    {
      RotateSquare2
    },
    created() 
    {
      window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() 
    {
      window.removeEventListener("scroll", this.handleScroll);
    },
    data: function () {
      return {
        
      };
    },
    methods: 
    {
      handleScroll: function () {},
      
      scrollHome: function () 
      {
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });

      },

      
    },
    computed: {},
    mounted: function () {
      this.scrollHome();     
    }
  }
</script>

<style scoped>
  @font-face 
  {
    font-family: monR;
    src: url('../assets/Montserrat/Montserrat-Regular.ttf');
  }

  @font-face 
  {
    font-family: monB;
    src: url('../assets/Montserrat/Montserrat-SemiBold.ttf');
  }

  a
  {
    text-decoration-color: #707070 !important;
  }
  
  .inicio
  {  
    overflow: auto;
    scroll-snap-type: y mandatory;
    background-image: url("../assets/iconos/textura1.png"), url("../assets/iconos/textura2.png");
    background-repeat: no-repeat, no-repeat;
    background-position: 90% 30%, bottom left;
    background-attachment: fixed;
  }

  nav 
  {
    position: fixed;
    width: 95%;
    height: 88px;
    left: 2.5%;
    top: 0;
        
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    margin-bottom: 30px;
    
  }

  nav p 
  {
    font-family: monB;
    font-size: 28px;
    color: #DDAAAA;
    letter-spacing: 1;
    margin: 0;
    cursor: pointer;
  }

  nav img
  {
    width: 38px;
    height: 20px;
    cursor: pointer;
  }

  .mainInner
  {
    width: 100%;
    height: auto;
    margin-top: 115px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .row 
  {
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .articulo
  {
    width: 100%;
    height: 330px;
    margin-bottom: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .articulo img 
  {
    width: 100%;
    height: auto;
    border: 2px dotted #DDAAAA;
    box-sizing: border-box;
  }

  .articulo img:hover
  {
    /*opacity: 0.7;*/
    box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.25);
    border: none;
  }

  .articulo a 
  {
    text-decoration: none;
  }

  .articulo p 
  {
    color: #3A3A3A;
    font-family: monR;
    font-size: 24px;
    margin: 0;
  }

  footer
  {
    width: 100%;
    height: 180px;
    margin-top: 30px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .redes
  {
    width: 25%;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .redes img 
  {
    width: 25px;
    height: 25px;
  }

  footer p 
  {
    margin: 0;
    color: #707070;
  }

  /*Tablets & larger smartphones*/
  @media (min-width:561px) and (max-width:850px)
  {
    .articulo
    {
      height: auto;
      margin-bottom: 50px;
    }

  }

  /*Laptops & largers tables in landscape and small desktop*/
  @media (min-width:851px) and (max-width:1279px)
  {
    .inicio
    {
      background-position: 90% 10%, 10% 80%;
    }
    .mainInner
    {
      margin-top: 150px;
    }

    .row
    {
      flex-direction: row;
    }
    .articulo
    {
      height: 300px;
      margin-bottom: 50px;
    }

    .articulo p 
    {
      font-size: 20px;
    }

    .articulo img 
    {
      width: 90%;
      height: 300px;
    }

  }

  /*Larger desktops and monitors*/
  @media (min-width:1280px)
  {
    .inicio
    {
      background-position: 90% 10%, 10% 80%;
    }

    .mainInner
    {
      margin-top: 150px;
    }

    .row
    {
      flex-direction: row;
    }
    .articulo
    {
      height: 330px;
      margin-bottom: 50px;
    }

    .articulo p 
    {
      font-size: 20px;
    }

    .articulo img 
    {
      width: 90%;
      height: 300px;
    }
  }

  
</style>