<template>
  <div class="contacto">

    <nav>
      <a href="/">
        <p>Mygirlweb</p>
      </a>      
    </nav>

    <div class="msj" v-if="msj">

      <p>¡Gracias!</p>
      <p>Hemos recibido tu mensaje y pronto nos comunicaremos contigo.</p>

    </div>

    <div class="mainInner" >
    
      <p class="title">Contacto</p>
    
      <div class="row" id="home">
        <div class="articulo">
          
          <p>Bienvenido a nuestro sitio web.</p>

          <p>¿Quieres saber más sobre mygirlweb o simplemente saludar? <br class="brDesk"> Envíanos un mensaje o visitanos en nuestras redes sociales.</p>

          <form onsubmit="return false">
            <input type="text" name="" id="" placeholder="Tu nombre*">
            <input type="email" name="" id="" placeholder="Correo electrónico*">
            <textarea name="" id="" cols="30" rows="10">Mensaje</textarea>
            <button v-on:click="btnForm">Enviar</button>
          </form>

          

  
        </div>

      </div>
      

   
      
      
    </div>
    
  </div>
</template>

<script>
  export default {
    created() 
    {
      window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() 
    {
      window.removeEventListener("scroll", this.handleScroll);
    },
    data: function () {
      return {
        msj: false
      };
    },
    methods: 
    {
      btnForm: function()
      {
        this.msj = true;

        setTimeout(() => {
          this.msj = false;
        }, 3000);
      },
      openMenu: function()
      {
        this.$store.state.menuOpc = true;
      }           
    },
    computed: {},
    mounted: function () {
      
    }
  }
</script>

<style scoped>
  @font-face 
  {
    font-family: monR;
    src: url('../assets/Montserrat/Montserrat-Regular.ttf');
  }

  @font-face 
  {
    font-family: monB;
    src: url('../assets/Montserrat/Montserrat-SemiBold.ttf');
  }

  a
  {
    text-decoration: none;
  }

  .contacto 
  {
    background-image: url("../assets/iconos/textura1.png"), url("../assets/iconos/textura2.png");
    background-repeat: no-repeat, no-repeat;
    background-position: 90% 30%, bottom left;
    background-attachment: fixed;
  }
  

  nav 
  {
    position: fixed;
    width: 95%;
    height: 88px;
    left: 2.5%;
    top: 0;
        
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
    margin-bottom: 30px;
    
  }

  nav p 
  {
    font-family: monB;
    font-size: 28px;
    color: #DDAAAA;
    letter-spacing: 1;
    margin: 0;
    cursor: pointer;
  }

  nav img
  {
    width: 38px;
    height: 20px;
    cursor: pointer;
  }

  .msj
  {
    position: fixed;
    width: 70%;
    height: 40%;
    top: 30%;
    left: 15%;
    background-color: #DDAAAA;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .msj p 
  {
    color: #fff;
    font-family: monR;
    font-size: 14px;
    padding: 0 10px;
  }
  
  .mainInner
  {
    width: 100%;
    height: auto;
    margin-top: 115px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .title
  {
    font-size: 30px;
    font-family: monB;
    margin: 0;
    color: #3A3A3A;
  }

  .subtitle
  {
    font-size: 24px;
    font-family: monB;
    color: #3A3A3A;    
  }

  .row 
  {
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .articulo
  {
    width: 100%;
    margin-bottom: 5px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .articulo img 
  {
    width: 100%;
    height: auto;
    border: 2px dotted #DDAAAA;
    box-sizing: border-box;
  }

  .articulo p 
  {
    color: #3A3A3A;
    font-family: monR;
    font-size: 24px;
    text-align: left;
    letter-spacing: 1px;
    
  }

  .brDesk
  {
    display: none;
  }

  .articulo form
  {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .articulo form input
  {
    width: 100%;
    height: 30px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #707070;
    margin-bottom: 10px;
    font-family: monR;
  }

  .articulo form textarea
  {
    width: 100%;
    resize: none;
    border: 1px solid #707070;
    color: #707070;
    margin-bottom: 10px;
    font-family: monR;
  }

  .articulo form button 
  {
    width: 124px;
    height: 31px;
    border: 1px solid #DDAAAA;
    background-color: #fff;
    color: #3A3A3A;
    font-size: 11px;
    font-family: monR;
    cursor: pointer;
  }

  .articulo form button:hover
  {
    border: none;
    background-color: #DDAAAA;
  }



  /*Tablets & larger smartphones*/
  @media (min-width:561px) and (max-width:850px)
  {
    .title
    {
      font-size: 34px;
    }

    .articulo p 
    {
      font-size: 28px;
    }

    .articulo form input
    {
      height: 50px;
      margin-bottom: 20px;
    }

    .articulo form button 
    {
      width: 200px;
      height: 40px;
      font-size: 16px;

    }
    .msj
    {
      width: 40%;
      left: 30%;
    }

  }

  /*Laptops & largers tables in landscape and small desktop*/
  @media (min-width:851px) and (max-width:1279px)
  {

    .title
    {
      font-size: 39px;
    }

    .articulo p 
    {
      font-size: 33px;
    }

    .articulo form input
    {
      height: 50px;
      margin-bottom: 20px;
    }

    .articulo form button 
    {
      width: 200px;
      height: 40px;
      font-size: 16px;

    }

    .msj
    {
      width: 30%;
      left: 35%;
    }

  }

  /*Larger desktops and monitors*/
  @media (min-width:1280px)
  {
    nav 
    {
      width: 98%;
      left: 1%;
    }

    nav p 
    {
      font-size: 40px;
    }

    nav img 
    {
      width: 43px;
      height: 18px;
    }
    .title
    {
      font-size: 25px;
    }

    .articulo p 
    {
      font-size: 20px;
    }

    .articulo form input
    {
      width: 30%;
      height: 30px;
      margin-bottom: 20px;
    }

    .articulo form textarea
    {
      width: 30%;
    }

    .articulo form button 
    {
      width: 100px;
      height: 40px;
      font-size: 16px;

    }

    .brDesk
    {
      display: block;
    }

    .msj
    {
      width: 30%;
      left: 35%;
    }

  }


  
</style>