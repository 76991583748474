<template>
  <div class="menuOpc">

   
    <div class="menuOpcInner" v-if="$store.state.menuOpc">

      <img id="close" src="../assets/iconos/close.png" alt="instagram" v-on:click="closeMenu">

      <div>
        <a href="/contacto">
          <p>Contacto</p>
        </a>
  
        <div class="redes">
          <a href="https://www.instagram.com/mygirlweb/" target="_blank">
            <img src="../assets/iconos/instagramB.png" alt="instagram">
          </a>
  
          <a href="https://www.facebook.com/Mygirlweb-932190477126404" target="_blank">
            <img src="../assets/iconos/facebookB.png" alt="">
          </a>
        </div>

      </div>
      
      
    </div>

   
  </div>
</template>

<script>
  export default {
    
    data: function () {
      return {
        
      };
    },
    methods: 
    {
      
      closeMenu:function()
      {
        this.$store.state.menuOpc = false;
      }        
    },
    computed: {},
    mounted: function () {
      
    }
  }
</script>

<style scoped>
  @font-face 
  {
    font-family: monR;
    src: url('../assets/Montserrat/Montserrat-Regular.ttf');
  }

  @font-face 
  {
    font-family: monB;
    src: url('../assets/Montserrat/Montserrat-SemiBold.ttf');
  }

  a
  {
    text-decoration: none;
  }

  

  .menuOpcInner
  {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #DDAAAA;
    z-index: 10;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #close 
  {
    position: fixed;
    width: 25px;
    height: 25px;
    top: 50px;
    cursor: pointer;
  }

  .menuOpcInner div 
  {
    
    height: auto;
  }

  .menuOpcInner p 
  {
    
    color: #fff;
    font-size: 24px;
    font-family: monR;
    letter-spacing: 1px;
  }

  .redes
  {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .redes img 
  {
    width: 25px;
    height: 25px;
  }


   /*Tablets & larger smartphones*/
   @media (min-width:561px) and (max-width:850px)
  {
    .menuOpcInner p 
    {
      font-size: 28px;
    }
    .redes img 
    {
      width: 30px;
      height: 30px;
    }
  }

  /*Laptops & largers tables in landscape and small desktop*/
  @media (min-width:851px) and (max-width:1279px)
  {
    .menuOpcInner p 
    {
      font-size: 33px;
    }
    .redes img 
    {
      width: 35px;
      height: 35px;
    }
  }

  /*Larger desktops and monitors*/
  @media (min-width:1280px)
  {

  }

  

  
</style>