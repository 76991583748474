import Vue from 'vue'
import VueRouter from 'vue-router'

import menu from '@/components/menu.vue'
import menuOpc from '@/components/menuOpc.vue'
import footer from '@/components/footer.vue'

import inicio from '@/components/inicio.vue'
import detalle from '@/components/detalle.vue'
import contacto from '@/components/contacto.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    components:
      {
        a: menu,
        b: inicio,
        c: footer,
        d: menuOpc
      }
  },
  {
    path: '/detalle',
    name: 'Detalle',
    components:
      {
        a: menu,
        b: detalle,
        c: footer,
        d: menuOpc
      }
  },
  {
    path: '/contacto',
    name: 'Contacto',
    components:
      {
        a: contacto,
        b: footer,
        c: menuOpc
      }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router


