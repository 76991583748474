<template>
  <div class="footer">    

      <!--FOOTER-->

      <footer>

        <div class="redes">
          <a href="https://www.instagram.com/mygirlweb/" target="_blank">
            <img src="../assets/iconos/instagram.png" alt="instagram">
          </a>

          <a href="https://www.facebook.com/Mygirlweb-932190477126404" target="_blank">
            <img src="../assets/iconos/facebook.png" alt="">
          </a>
        </div>

        <a href="/contacto"><p>Contacto</p></a>
        
        <p>Hecho con &hearts; por <a href="https://adhok.mx/" target="_blank">Adhok</a> </p>
        <p>2022 © // All rights reserved</p>

      </footer>
      
      
    </div>
    
  </div>
</template>

<script>
  export default {
    
    data: function () {
      return {
        
      };
    },
    methods: 
    {
      
           
    },
    computed: {},
    mounted: function () {
      
    }
  }
</script>

<style scoped>
  @font-face 
  {
    font-family: monR;
    src: url('../assets/Montserrat/Montserrat-Regular.ttf');
  }

  @font-face 
  {
    font-family: monB;
    src: url('../assets/Montserrat/Montserrat-SemiBold.ttf');
  }

  a
  {
    text-decoration-color: #707070 !important;
  }

    
  footer
  {
    width: 100%;
    height: 180px;
    margin-top: 30px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .redes
  {
    width: 25%;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .redes img 
  {
    width: 25px;
    height: 25px;
  }

  footer p 
  {
    margin: 0;
    color: #707070;
    font-family: monR;
  }

   /*Tablets & larger smartphones*/
  @media (min-width:561px) and (max-width:850px)
  {
    .redes 
    {
      width: 15%;
    }

    footer p 
    {
      font-size: 16px;
    }

  }

  /*Laptops & largers tables in landscape and small desktop*/
  @media (min-width:851px) and (max-width:1279px)
  {
    .redes 
    {
      width: 10%;
    }

    footer p 
    {
      font-size: 16px;
    }
  }

  /*Larger desktops and monitors*/
  @media (min-width:1280px)
  {
    .redes 
    {
      width: 5%;
    }

    footer p 
    {
      font-size: 14px;
    }
  }


  
</style>